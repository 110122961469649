<template>
<div class="lct-inclinometer-container" :style="style" :class="inclinometerClass">
   <div class="inclinometer-id">{{inclinometer.id}}</div>
   <div class="inclinometer-pitch inclinometer-value-label">
  
     <div class="inclinometer-label">Pitch:</div>
     <div class="inclinometer-value">
      {{pitch}}°
     </div>
    </div>

   <div class="inclinometer-roll inclinometer-value-label">
    <div class="inclinometer-label">Roll:</div>
    <div class="inclinometer-value">
    {{roll}}° 
    </div>
   </div>

   <div class="inclinometer-yaw inclinometer-value-label">
      <div class="inclinometer-label">Yaw:</div>
      <div class="inclinometer-value">
        {{yaw}}°
      </div>
    </div>

   <div class="inclinometer-angle inclinometer-value-label">
      <div class="inclinometer-label">Angle:</div>
      <div class="inclinometer-value">
        {{vectorAngle}}°
      </div>
    </div>

  <div class="inclinometer-zero inclinometer-value-label">
      <div class="inclinometer-button" @click="doZeroInclinometer">Zero</div>
  </div>



    <div class="scene">
      <div
        class="cube"
        :style="cubeStyle"
      >
        <div class="cube-face front">Front</div>
        <div class="cube-face back">Back</div>
        <div class="cube-face left">Left</div>
        <div class="cube-face right">Right</div>
        <div class="cube-face top">Top</div>
        <div class="cube-face bottom">Bottom</div>

      </div>
    </div>
    </div>
 
</template>

<script>

import {mapState,mapActions} from 'vuex';

export default {
  props: {  
    inclinometer:{type:Object,required:true},
    angles:{type:Object,required:true},
  },
  data() {
    return {}
  },
  methods:{...mapActions(['db_create_document']),
    doZeroInclinometer() {
      let updateRecord = {collection_path:'/inclinometers',id:this.inclinometer.id,deleted:false};
      updateRecord.zeroRoll = this.inclinometer.roll;
      updateRecord.zeroYaw = this.inclinometer.yaw;
      updateRecord.zeroPitch = this.inclinometer.pitch;
      this.db_create_document({record:updateRecord,options:{on_success:this.onZeroSuccess}})
    },
    onZeroSuccess(params) {
    }
  },
  computed: {
    ...mapState(['inclinometers']),
    zeroInclinometer() {
      if (this.inclinometers[this.inclinometer.id] === undefined) { 
        return {zeroPitch:0,zeroRoll:0,zeroYaw:0,id:this.inclinometer.id}
      }
      else 
      {
        return this.inclinometers[this.inclinometer.id]
      }
    },
    pitch() { return (this.inclinometer.pitch - this.zeroInclinometer.zeroPitch).toFixed(3)},
    roll() { return (this.inclinometer.roll  - this.zeroInclinometer.zeroRoll).toFixed(3)},   
    yaw() { return (this.inclinometer.yaw -  this.zeroInclinometer.zeroYaw).toFixed(3)},  
    vectorAngle() {
      return this.angles[this.inclinometer.id].vectorAngle.toFixed(3);
    },
    inclinometerClass() {
      let classes = [];
      let onlineClass = (this.inclinometer.Online === 1) ? 'online' : 'offline' ;
      classes.push(onlineClass);
      return classes.join(' ');
    },
    style() { return {} },
    cubeStyle() {
      return {
        transform: `rotateX(${this.pitch}deg) rotateY(${this.yaw}deg) rotateZ(${this.roll}deg)`,
      };
    },
  },
  mounted() {
    // Simulate accelerometer data updates
  },
};
</script>

<style scoped>

div.lct-inclinometer-container{
   border: 2px solid var(--online-color);
   position:relative;
   display:flex;
   min-height: 100px;   
   margin:5px;
   max-width:300px;
   min-width:300px;
   border-radius: 5px;
   padding:5px;
   --pitch-color:#007E33;
   --roll-color:#CC0000;
   --yaw-color:#0099CC;
   --zero-color:#186CEF;
}

div.inclinometer-id{
  flex:3;
  min-width: 150px;
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  background-color:var(--online-color);
  color:white;
  font-weight: bold;
  text-transform: uppercase;
}

div.inclinometer-pitch{
  top:30px;
  color:var(--pitch-color);
}

div.inclinometer-roll{
  top:60px;
  color:var(--roll-color);
}

div.inclinometer-yaw{
  top:90px;
  color:var(--yaw-color);
}

div.inclinometer-angle{
  top:120px;
  color:var(--angle-color);
}




div.inclinometer-value-label{
  position: absolute;
  right:5px;
  display:flex;
  max-height:25px;
  min-height: 25px;
  align-items:center;
  min-width: 100px;
}

div.inclinometer-zero{
  top:180px;
  color:var(--button-color);
  text-align: center;
  display:flex;
}


div.inclinometer-button{
  flex:1;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid var(--zero-color);
  font-weight: bold;
  background-color:var(--zero-color);
  color:white;
  box-shadow: 2px 2px 2px #CCC;
}


div.inclinometer-value{
  font-weight: bold;
  flex:1;
  text-align: right;
}

div.inclinometer-value{
  flex:1;
}

div.lct-inclinometer-container.online{
  --online-color:#178B4D;
}

div.lct-inclinometer-container.offline{
  --online-color:#C42511;
}


/* 3D scene setup */
.scene {
  width: 200px;
  height: 200px;
  perspective: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Cube setup */
.cube {
  top:10px;
  position: relative;
  width: 100px;
  height: 100px;
  transform-style: preserve-3d;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  transition: transform 0.5s ease-in-out;
}

/* Cube faces */
.cube-face {
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.7;
}

/* Face positions */
.cube-face.front {
  transform: translateZ(50px);
  background-color:var(--roll-color);
}
.cube-face.back {
  transform: rotateY(180deg) translateZ(50px);
  background-color:var(--roll-color);
}
.cube-face.left {
  transform: rotateY(-90deg) translateZ(50px);
  background-color:var(--pitch-color);
}
.cube-face.right {
  transform: rotateY(90deg) translateZ(50px);
  background-color:var(--pitch-color);
}
.cube-face.top {
  transform: rotateX(90deg) translateZ(50px);
  background-color:var(--yaw-color);
}
.cube-face.bottom {
  transform: rotateX(-90deg) translateZ(50px);
  background-color:var(--yaw-color);
}
</style>