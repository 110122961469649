<template>
    <div  class="lct-graph-container">
    <LCTWeightTempChart :logs="mappedLogs" :setup="setup" />
    </div>
</template>


<script>

import LCTWeightTempChart from './LCTWeightTempChart.vue';
import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

function convertCustomTimestamp(timestamp) {
  // Extract the components from the string using substring
  const year = parseInt(timestamp.substring(0, 4));
  const month = parseInt(timestamp.substring(4, 6)) - 1; // JavaScript months are 0-based
  const day = parseInt(timestamp.substring(6, 8));
  const hour = parseInt(timestamp.substring(8, 10));
  const minute = parseInt(timestamp.substring(10, 12));
  const second = parseInt(timestamp.substring(12, 14));

  // Create a new Date object
  const date = new Date(year, month, day, hour, minute, second);

  return date.toISOString(); // Returns ISO 8601 formatted string
}


export default {
  components: {LCTWeightTempChart},
  props: {  
    testID:{type:String,required:true},
    logs:{type:Array,required:true},
    loadCellType:{type:String,required:true},
    temperatureSensor:{type:String,required:true},
    loadCellID:{type:String,required:true},
    sCellID:{type:String,required:true},
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
  mounted() {
    
    },
  computed:{
    ...mapGetters([]),
  	...mapState(['tests']),
    currentTest() {
      return this.tests[this.testID];
    },
    setup() {
      return this.currentTest.setup;
    },
    mappedLogs() {
      let res = [];
      let xAxisField = this.setup.x_axis.field;
      for (let l = 0 ; l < this.logs.length ; l ++ )  {
        let log = this.logs[l];
        if (log.id !== 'latest') {
      
          let ll = {};
          if (this.setup.x_axis.type === 'time') {
            ll[xAxisField] = convertCustomTimestamp(log[xAxisField]);
          }
          else 
          {
            ll[xAxisField] = convertCustomTimestamp(log[xAxisField]);
          }  
          for (let f = 0 ; f < this.setup.y_axis.length ; f ++  ) {
            let yAxisSetup = this.setup.y_axis[f];
            let group = yAxisSetup.group;
            let sensor = yAxisSetup.sensor;
            let valueField = yAxisSetup.value;
            let fieldID = yAxisSetup.id;
            let value = log[group][sensor][valueField];
            ll[fieldID] = value;
          }
          res.push(ll);
        }
      }

      // const logs = [
      //   { time: '2024-10-01T08:30:00Z', temperature: 22, weight: 75 },
      //   { time: '2024-10-01T09:30:00Z', temperature: 23, weight: 76 },
      //   { time: '2024-10-01T10:30:00Z', temperature: 24, weight: 74 },
      //   { time: '2024-10-01T11:30:00Z', temperature: 25, weight: 73 },
      //   { time: '2024-10-01T12:30:00Z', temperature: 26, weight: 77 },
      //   { time: '2024-10-01T13:30:00Z', temperature: 27, weight: 78 },
      //   { time: '2024-10-01T14:30:00Z', temperature: 28, weight: 76 },
      // ];


      // return logs;

      return res;
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>

<style>

	div.container{

  }

</style>


