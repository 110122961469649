// @ts-nocheck
import { createStore } from 'vuex'

import {db_interface_firestore as db_interface,db_vuex_actions,db_vuex_state,db_vuex_getters}  from '@/db_interface';

function formatDate(date) {
  const year = date.getFullYear();                // CCYY
  const month = String(date.getMonth() + 1).padStart(2, '0'); // MM
  const day = String(date.getDate()).padStart(2, '0');        // DD
  return `${year}${month}${day}`;
}

const state = {
  ...db_vuex_state,
  search_string:'',
  bind_info:{},
  show_create_view:false,
  definitions:{},
  ui_auth:undefined,
  is_authenticated:false,
  auth_created:false,
  hover:{},
  sse:Math.floor(new Date().getTime() / 1000),
  active:{},
  hidden:{},
  users:{},
  logs:{},
  loadcells:{},
  inclinometers:{},
  tests:{},
  thermometers:{},
};


const actions = {
  ...db_interface,
  ...db_vuex_actions,
  set_is_authenticated:(context,is_authenticated) => {
    context.state.is_authenticated = is_authenticated
  },
  user_authenticated:(context,user) => {
    // console.error('CALLING USER AUTHENTICATED!',user);
    context.dispatch('db_user_authenticated',user);
    context.dispatch('db_get_document',{collection_path:'/users',id:user.id});
    context.state.active.user = user.id;
    context.state.is_authenticated = true;
  },
  set_now_time:(context) => {
    context.state.now_time = long_utc();
   
    context.state.sse = Math.floor(new Date().getTime() / 1000);
  },
  global_set_hidden:(context,payload) => {
    let {record,id,hidden} = payload;
    if (context.state.hidden[record] === undefined) {
      context.state.hidden[record] = {};
    }
    context.state.hidden[record][id] = hidden;
  },
}

const getters = {
  ...db_vuex_getters,
  latest_log:(state) => {
    let dateStr = formatDate(new Date());
    return state?.results?.[dateStr]?.logs?.latest;
  }
}

const mutations = {};

const modules = {};

export const store =  createStore({
  state,
  getters,
  mutations,
  actions,
  modules
})

window.state = state;
window.store = store;
